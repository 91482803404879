import React, { useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import LoadingIndicator from 'src/components/LoadingIndicator'
import apiService from 'src/services/api'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'
import { useFundData } from 'src/hooks/use-fund-data'

import { TemplatesHistory } from './templates-history'
import { FundName } from '../fund-name'
import { TemplateCard } from './template-card'

import { EAirdropIssueType } from 'src/types/common.types'

let interval = null as any

export const Airdrops = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [events, handleEvents] = useState<Record<string, any>>({})

  const navigate = useNavigate()
  const isLaptop = useMediaQuery('@media(min-width: 1200px)')

  const { urlName, fundId } = useFundData()

  const { isAllowed: isAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin, UserRoles.Admin] })

  const fetchEvents = useCallback(async () => {
    try {
      const eventsRes = await apiService.getAirdropEvents({
        offset: 10,
        page: events.page,
        fundId
      })
      handleEvents(eventsRes.data)
    } catch (error) {}
  }, [events.page, fundId])

  useEffect(() => {
    clearInterval(interval)
    fetchEvents()
    interval = setInterval(fetchEvents, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [fetchEvents])

  const handleChangePagination = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    try {
      setIsLoading(true)
      const eventsRes = await apiService.getAirdropEvents({ offset: 10, page: newPage, fundId })
      handleEvents(eventsRes.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const openOneTime = (issueType: EAirdropIssueType) => {
    navigate(`/admin/fund/${urlName}/airdrops/manual?issueType=${issueType}`)
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      <FundName />

      {isLaptop && isAdmin && (
        <>
          <SectionLabel>Airdrops</SectionLabel>
          <TemplateCard openForm={openOneTime} />
        </>
      )}
      <TemplatesHistory events={events} handleChangePagination={handleChangePagination} />
    </Container>
  )
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`
