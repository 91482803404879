import React, { useState } from 'react'
import { toast } from 'react-toastify'

import styled from '@emotion/styled'

import apiService from 'src/services/api'

import { ConfirmModal } from 'src/components/Modal/confirm-modal'
import { truncateAddress } from 'src/utils/truncateAddress'

interface UseDeleteInvestorProps {
  investorName: string
  walletAddress: string
  investorId: number
  callback?: () => void
}

export const useDeleteInvestor = ({
  investorName,
  walletAddress,
  investorId,
  callback
}: UseDeleteInvestorProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const deleteInvestor = async () => {
    try {
      if (isLoading) return

      closeModal()
      setIsLoading(true)

      await apiService.deleteInvestor({ id: investorId })

      toast.success(
        <BoltToastMessage>
          <div>Investor has been deleted successfully</div>
        </BoltToastMessage>
      )

      if (callback) callback()
    } catch (error: any) {
      const message =
        error?.response?.data?.message || 'Failed to delete investor. Please try again.'
      toast.error(message)
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  const DeleteInvestorModal = () => (
    <ConfirmModal
      isOpen={isModalOpen}
      handleClose={closeModal}
      onConfirm={deleteInvestor}
      confirmText="Yes"
      closeText="No"
      title={
        `By confirming this action, the investor ` +
        `${investorName} ${truncateAddress(walletAddress)} and all associated investments ` +
        `will be permanently deleted from the fund.`
      }
    />
  )

  return { openModal, closeModal, DeleteInvestorModal }
}

const BoltToastMessage = styled('div')`
  font-weight: 600;
`
