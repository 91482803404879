import React from 'react'
import styled from '@emotion/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { ButtonPrimary } from 'src/components/common'
import { EAirdropIssueType } from 'src/types/common.types'
import { issuesFormOptions } from './constants'

export interface TemplateCardProps {
  openForm: (issueType: EAirdropIssueType) => void
}

export const TemplateCard = ({ openForm }: TemplateCardProps) => {
  return (
    <Container>
      {issuesFormOptions.map((form, index) => (
        <OneTimeContainer key={index}>
          <div>
            <div>{form.title}</div>
            <div>{form.description}</div>
          </div>
          <SubmitBtn onClick={() => openForm(form.issueType)}>
            Submit
            <ArrowForwardIosIcon sx={{ width: '16px' }} />
          </SubmitBtn>
        </OneTimeContainer>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SubmitBtn = styled(ButtonPrimary)`
  min-height: 36px;
  height: 36px;
  width: 110px;
  min-width: 110px !important;
  text-transform: none;
  border-radius: 18px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 22px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
`

const OneTimeContainer = styled.div`
  padding: 20px 24px;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  > div:first-of-type {
    display: flex;
    gap: 8px;
    flex-direction: column;
    > div:first-of-type {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
    > div:last-of-type {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .MuiIconButton-root {
    padding: 0px !important;
  }
`
