import React, { useState } from 'react'

import moment from 'moment'
import styled from '@emotion/styled'

import { css, TableContainer, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Table, HeaderRow, BodyRow, CellPrimary, StyledBox, IconWrapper } from '../../Table'
import { AddressesCell } from '../../Table/AddressesCell'
import { Dropdown } from 'src/components/common/Dropdown'

import { useHaveAccess } from 'src/hooks/useAccessController'
import { useIsOpen } from 'src/hooks/use-is-open'

import { Investment } from 'src/types/common.types'
import { AllotTokens } from '../investors/allot-tokens-modal'

import { colors } from 'src/constants/colors'
import { UserRoles } from 'src/constants'

import { truncateAddress } from 'src/utils/truncateAddress'
import { EditInvestment } from './utils/editInvestmentHandler'
import { DeleteInvestmentModal } from './utils/deleteInvestmentHandler'

import deleteIcon from 'src/assets/minus.jpg'

interface Props {
  investments: Investment[]
  onSuccess?: (data?: any) => void
}

const headerData: string[] = ['#', 'Date', 'Investor', 'Token amount', 'Execution', '']

export const InvestmentsTable = ({ investments, onSuccess }: Props) => {
  const [investment, setInvestment] = useState<Investment>()
  const [selectedInvestmentId, setSelectedInvestmentId] = useState<number | null>(null)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const { isOpen: isAllotTokensOpen, close: closeAllotTokens, open: openAllotTokens } = useIsOpen()

  const { isAllowed: isSuperAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin] })
  const { isAllowed: isAdmin } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Editor]
  })

  const openModal = (entry: Investment) => {
    setInvestment(entry)
    openAllotTokens()
  }

  const openDeleteModal = (investmentId: number) => {
    setSelectedInvestmentId(investmentId)
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const getTruncatedAddress = (address: string) => truncateAddress(address, 2)

  const renderAddressesRow = (row: Investment, id: string) => {
    const address = row.investor.walletAddress
    const name = row.investor.name
    const truncatedAddress = getTruncatedAddress(address)

    return (
      <AddressesCell
        address={address}
        name={name}
        id={id}
        isNoAccount={truncatedAddress === 'No Account'}
        isNoAccountMsg={'dApp not completed'}
      />
    )
  }

  return (
    <>
      {isAllotTokensOpen && (
        <AllotTokens
          isEdit
          investment={investment}
          ethAddress={investment?.investor.walletAddress}
          onClose={closeAllotTokens}
          onSuccess={onSuccess}
        />
      )}

      {selectedInvestmentId && (
        <DeleteInvestmentModal
          investmentId={selectedInvestmentId}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onSuccess={onSuccess}
        />
      )}

      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '900px' }}>
          <HeaderRow rowTemplate={rowTemplate(isAdmin)}>
            {headerData.map((header, index) =>
              !isAdmin && index === 5 ? null : <CellPrimary key={header}>{header}</CellPrimary>
            )}
          </HeaderRow>
          <>
            {investments.map((row) => (
              <StyledBodyRow key={row.id} rowTemplate={rowTemplate(isAdmin)}>
                <CellPrimary>{row.id}</CellPrimary>
                <CellPrimary>
                  <Tooltip
                    title={
                      <>
                        {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                        <br />
                        {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                      </>
                    }
                    placement="top"
                    arrow
                  >
                    <div>{moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)</div>
                  </Tooltip>
                </CellPrimary>
                <CellPrimary>{renderAddressesRow(row, row.id.toString())}</CellPrimary>
                <CellPrimary>
                  <Tokens type={row.investment > 0 ? 'add' : 'subtract'}>
                    {row.investment > 0 ? '+' : '-'}
                    {Math.abs(+row.investment).toLocaleString('en-US')}
                  </Tokens>
                </CellPrimary>
                <CellPrimary>Manual</CellPrimary>
                {isAdmin && (
                  <Dropdown
                    menuContent={
                      <ActionsMenu>
                        <EditInvestment investment={row} onEdit={openModal} />

                        {isSuperAdmin && (
                          <StyledBox
                            onClick={() => openDeleteModal(row.id)}
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                          >
                            <img src={deleteIcon} alt="deleteIcon" />
                            Delete
                          </StyledBox>
                        )}
                      </ActionsMenu>
                    }
                  >
                    <IconWrapper>
                      <MoreVertIcon fontSize="small" />
                    </IconWrapper>
                  </Dropdown>
                )}
              </StyledBodyRow>
            ))}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

const rowTemplate = (isAdmin?: boolean) =>
  isAdmin
    ? css`
        grid-template-columns: 5rem repeat(4, minmax(170px, 1fr)) 8rem;
      `
    : css`
        grid-template-columns: 5rem repeat(4, minmax(170px, 1fr));
      `

const Tokens = styled.div<{ type: string }>`
  color: ${({ type }) => (type === 'add' ? '#1EB173' : '#E55252')} !important;
`

const StyledBodyRow = styled(BodyRow)<{ deactivated?: boolean }>`
  ${({ deactivated }) =>
    deactivated &&
    css`
      *:not(svg, path, .copied, button) {
        color: ${colors.$primaryLight} !important;
      }
    `};
`

const ActionsMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
