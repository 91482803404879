import { useState } from 'react'
import { toast } from 'react-toastify'

import { ConfirmModal } from 'src/components/Modal/confirm-modal'
import apiService from 'src/services/api'

interface Props {
  investmentId: number
  onSuccess?: () => void
  isOpen: boolean
  onClose: () => void
}

export const DeleteInvestmentModal = ({ investmentId, onSuccess, isOpen, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const deleteInvestment = async () => {
    try {
      if (isLoading) return

      setIsLoading(true)

      await apiService.deleteInvestment({ investmentId })

      toast.success('Investment has been deleted successfully')

      if (onSuccess) onSuccess()
    } catch (error: any) {
      const message =
        error?.response?.data?.message || 'Failed to delete investment. Please try again.'
      toast.error(message)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      handleClose={onClose}
      onConfirm={deleteInvestment}
      confirmText="Yes"
      closeText="No"
      title="By confirming this action, the investment transaction will be permanently deleted from the fund"
    />
  )
}
