import axios from 'axios'

import { GetLogsParams } from 'src/state/effector/history/logs.types'
import { setAuth } from 'src/state/effector/store'

import { API_URL } from '../config'
import { metamask, unauthorizedUrls } from './apiUrls'
import { Login, MetamaskChallenge } from './types'
import { PaginationTypes } from '../types/pagination.types'
import { responseRejectInterceptor } from './interceptors'

const getHeaders = (): Record<string, any> => {
  const auth = JSON.parse((window as any).localStorage.getItem('auth'))

  const address = (window as any).localStorage.getItem('walletAddr')
  if (auth?.[address]) {
    return { Authorization: `Bearer ${auth?.[address].accessToken}` }
  } else {
    if (auth && Object.keys(auth).length) {
      delete auth?.[address]
      setAuth(auth)
    }
  }

  return {}
}

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const headers = getHeaders()

    const withAuthorization = !unauthorizedUrls.find((url) =>
      config?.url ? config?.url?.includes(url) || url.includes(config?.url) : true
    )

    if (withAuthorization && !headers.Authorization) {
      return Promise.reject(new Error('Missing Authorization'))
    }

    config = {
      ...config,
      headers
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.request.use((config) => {
  const baseURL = API_URL
  // const baseURL = 'http://localhost:8000/v1/'

  // development env
  //const baseURL = 'https://dev.tokenee.com:3000/v1/'

  // staging
  // const baseURL = 'https://staging.tokenee.com:3000/v1/'

  // prod
  // const baseURL = 'https://api.tokenee.com/v1/'

  return { ...config, baseURL }
})

axios.interceptors.response.use((res) => res, responseRejectInterceptor)

const sendMetamaskAddress = (address: string) => {
  return axios.post<MetamaskChallenge>(metamask.challenge, { address })
}

const login = (address: string, hash: string) => {
  return axios.post<Login>(metamask.login, { address, hash })
}

const isBEHealth = () => {
  return axios.get('health')
}

const getLastMaintenanceState = () => {
  return axios.get('getLastMaintenanceState')
}

const switchMaintenanceMode = (enabled: boolean) => {
  const params = {
    enabled
  }
  return axios.post(`dangerous/maintenance?enabled=${enabled}`, params)
}

const getUser = () => {
  return axios.get(`auth/me`)
}

const addTeamMemberValidate = (params: Record<string, any>) => {
  return axios.get(`users/addTeamMemberValidate`, { params })
}

// history
const getLogs = (params: GetLogsParams) => {
  return axios.get(`history/logs`, { params })
}

export const refreshAuthToken = (refreshToken: string) => {
  return axios.post(`${API_URL}/auth/refresh`, {
    refreshToken
  })
}

const getAllEventsName = (fundId: number) => {
  return axios.get(`/rewardTransaction/events/${fundId}`)
}

interface GetAllRewards extends PaginationTypes {
  eventName?: string
  search?: string
  executionType?: string
  eventType?: string
  fundId: number
}

const getAllRewards = ({ fundId, ...params }: GetAllRewards) => {
  return axios.get(`/rewardTransaction/rewards/${fundId}`, { params })
}

const getMembersForAirdrop = (params: { fundId: number; search?: string }) => {
  return axios.get('/airdropEvent/buddies', { params })
}

const createAirdropTemplate = (data: Record<string, any>) => {
  return axios.post('/airdropEvent/template', data)
}

const updateAirdropTemplate = ({
  data,
  templateId
}: {
  templateId: number
  data: Record<string, any>
}) => {
  return axios.put(`/airdropEvent/template/${templateId}`, data)
}

const getAirdropTemplate = (params?: Record<string, any>) => {
  return axios.get('/airdropEvent/templates', { params })
}

const getAirdropMembersCalculations = ({
  templateId,
  ...params
}: {
  templateId: number
  [key: string]: any
}) => {
  return axios.get(`/airdropEvent/members/${templateId}`, { params })
}

const getAirdropSummary = ({
  templateId,
  networkId
}: {
  templateId: number
  networkId: number
}) => {
  return axios.get(`/airdropEvent/summary/${templateId}`, { params: { networkId } })
}

const getTemplate = (templateId: number) => {
  return axios.get(`/airdropEvent/templates/${templateId}`)
}

interface DraftAirdrop {
  template: number
  name: string
  percentage?: string
  budget?: string
  network: string
  specificMembers?: Array<{ ethAddress: string; amount: string }>
}

const createAirdropDraft = (data: DraftAirdrop) => {
  return axios.post('/airdropEvent/draft-event', data)
}

const deleteAirdropDraft = (id: number) => {
  return axios.delete(`/airdropEvent/draft-event/${id}`)
}

const executeAirdrop = (draftId: number) => {
  return axios.put(`/airdropEvent/execute/${draftId}`)
}

const getAirdropEvents = (params: any) => {
  return axios.get('/airdropEvent/events', { params })
}

const getAirdropEvent = (id: number) => {
  return axios.get(`/airdropEvent/events/${id}`)
}
const archiveTemplate = (id: number) => {
  return axios.delete(`/airdropEvent/template/${id}`)
}
const unarchiveTemplate = (id: number) => {
  return axios.put(`/airdropEvent/template/restore/${id}`)
}

const getManualSummary = ({ fundId, ...params }: Record<string, any>) => {
  return axios.get(`/airdropEvent/summary/${fundId}`, { params })
}

const createOneTimeEvent = (data: Record<string, any>) => {
  return axios.post('/airdropEvent/onetime-event', data)
}

const retryAirdropEvent = (id: number) => {
  return axios.put(`/airdropEvent/retry/${id}`)
}

const generateCsv = (id: number) => {
  return axios(`airdropEvent/csv/${id}`, {
    method: 'GET',
    responseType: 'blob'
  })
}

const getFundsList = () => {
  return axios.get('/funds/list')
}

const getFunds = (params: { page: number; offset: number; [key: string]: string | number }) => {
  return axios.get('/funds', { params })
}

const getFundById = (fundId: number) => {
  return axios.get(`/funds/${fundId}`)
}

const getTeamMembers = (params: Record<string, string | number>) => {
  return axios.get(`/funds/team`, { params })
}

const getFundTeamMembers = ({ fundId, ...params }: Record<string, string | number>) => {
  return axios.get(`/funds/${fundId}/team`, { params })
}

export interface CreateTeamMember {
  name: string
  walletAddress: string
  role: string
  funds?: number[]
  fundId?: number
}

const createTeamMember = (data: CreateTeamMember) => {
  return axios.post('/funds/team', data)
}

const createFundTeamMember = ({ fundId, ...data }: CreateTeamMember) => {
  return axios.post(`/funds/${fundId}/team`, data)
}

const editTeamMember = ({ id, ...data }: Record<string, any>) => {
  return axios.put(`/funds/team/${id}`, data)
}

const editFundTeamMember = ({ id, fundId, ...data }: Record<string, any>) => {
  return axios.put(`/funds/${fundId}/team/${id}`, data)
}

const deactivateFundTeamMember = ({ id, deactivate }: { id: number; deactivate: boolean }) => {
  return axios.put(`/funds/team/${id}/deactivate`, { deactivate })
}

interface DeactivateTeamMember {
  userId: number
  funds: number[]
  deactivate: boolean
}

const deactivateTeamMember = (data: DeactivateTeamMember) => {
  return axios.put('/funds/team/deactivate', data)
}

const getFundInvestors = ({ fundId, ...params }: Record<string, any>) => {
  return axios.get(`/funds/${fundId}/investors`, { params })
}

const getInvestorById = (id: number) => {
  return axios.get(`/funds/investors/${id}`)
}

interface GetFundInvestorByWallet {
  fundId: number
  walletAddress: string
}

const getFundInvestorByWallet = ({ fundId, ...params }: GetFundInvestorByWallet) => {
  return axios.get(`funds/${fundId}/investor`, { params })
}

const addInvestorToFund = ({ fundId, ...data }: Record<string, any>) => {
  return axios.post(`/funds/${fundId}/investors`, data)
}

interface ValidateInvestor {
  fundId: number
  investorWallet?: string
  introducerWallet?: string
}
const validateInvestor = ({ fundId, ...params }: ValidateInvestor) => {
  return axios.get(`/funds/${fundId}/investor/validate`, { params })
}

const editInvestor = ({ id, ...data }: Record<string, any>) => {
  return axios.put(`/funds/investors/${id}`, data)
}

const deleteInvestor = ({ id }: Record<string, any>) => {
  return axios.delete(`/funds/investors/${id}`)
}

const hideInvestor = ({ investorId, ...data }: { investorId: number; hidden: boolean }) => {
  return axios.put(`funds/investors/${investorId}/setHidden`, data)
}

const getResources = (fundId: number) => {
  return axios.get(`/funds/resources/${fundId}`)
}

const getInvestments = ({ fundId, ...params }: Record<string, any>) => {
  return axios.get(`/funds/${fundId}/investments`, { params })
}

interface AddInvestment {
  id: number
  investment: number
  investmentDate: string
}

interface EditInvestment {
  investmentId?: number
  investment: number
  investmentDate: string
}

const addInvestment = ({ id, ...rest }: AddInvestment) => {
  return axios.post(`/funds/investments/${id}`, { ...rest })
}

const editInvestment = ({ investmentId, ...rest }: EditInvestment) => {
  return axios.put(`/funds/investments/${investmentId}`, { ...rest })
}

const deleteInvestment = ({ investmentId }: Record<string, any>) => {
  return axios.delete(`/funds/investments/${investmentId}`)
}

const getFundLogs = ({ fundId, ...params }: GetLogsParams) => {
  return axios.get(`/funds/logs/${fundId}`, { params })
}

const apiService = {
  sendMetamaskAddress,
  login,
  getUser,
  addTeamMemberValidate,
  editTeamMember,
  getResources,
  getLogs,
  refreshAuthToken,
  isBEHealth,
  getLastMaintenanceState,
  switchMaintenanceMode,
  getAllRewards,
  getAllEventsName,
  createAirdropTemplate,
  getAirdropTemplate,
  getMembersForAirdrop,
  getAirdropMembersCalculations,
  getAirdropSummary,
  getTemplate,
  updateAirdropTemplate,
  createAirdropDraft,
  deleteAirdropDraft,
  executeAirdrop,
  getAirdropEvents,
  getAirdropEvent,
  archiveTemplate,
  unarchiveTemplate,
  createOneTimeEvent,
  getManualSummary,
  retryAirdropEvent,
  generateCsv,
  getFunds,
  getFundById,
  getTeamMembers,
  createTeamMember,
  createFundTeamMember,
  getFundsList,
  getFundInvestors,
  addInvestorToFund,
  editInvestor,
  addInvestment,
  editInvestment,
  deleteInvestment,
  getInvestorById,
  getInvestments,
  validateInvestor,
  getFundInvestorByWallet,
  hideInvestor,
  deleteInvestor,
  getFundTeamMembers,
  getFundLogs,
  deactivateFundTeamMember,
  deactivateTeamMember,
  editFundTeamMember
}

export default apiService
