import { StyledBox } from 'src/components/AdminPanel/Table'
import { Investment } from 'src/types/common.types'

import editIcon from 'src/assets/edit.svg'

interface Props {
  investment: Investment
  onEdit: (data: Investment) => void
}

export const EditInvestment = ({ investment, onEdit }: Props) => {
  return (
    <StyledBox
      onClick={() => onEdit(investment)}
      display="flex"
      alignItems="center"
      justifyContent="start"
    >
      <img src={editIcon} alt="editIcon" />
      Edit
    </StyledBox>
  )
}
